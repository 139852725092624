<template>
    <div class="">

        <!-- Page Banner Start-->
        <PageBanner
            :pgLoading="pgLoading"
            :background="(item.background) ? item.background.url : ''"
            :prevSlug="'properties'"
            :prevPage="($i18n.locale == 'ar') ? 'العقارات' : 'Properties'"
            :landing= true
            :currentPage="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
        </PageBanner>
        <!-- Page Banner End -->


        <!-- Property Detail -->
        <section id="property" class="padding">
          <div class="container">
            <div class="row">
              <div class="col-md-8 listing1 property-details">
                <h2 class="text-uppercase" 
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                    v-html="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
                </h2>
                <p class="bottom30"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    {{ (item.location) ?($i18n.locale == 'ar') ? item.location.ar.title : item.location.en.title : '' }}
                </p>

                <div class="col-md-12 pd0">

                    <div id="full-slider">
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div @click="prev()" 
                                    id="swiperBtnPrev" 
                                    class="owl-prev">
                                    <span class="fa fa-angle-left"></span>
                                </div>
                                <div @click="next()" 
                                        id="swiperBtnNext" 
                                        class="owl-next">
                                        <span class="fa fa-angle-right"></span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Swiper ref="mySwiper" :options="swiperOptions">
                            <SwiperSlide v-for="(gallery, index) in item.galleries" :key="index">
                                <div class="item">
                                    <img
                                        :src="gallery.url" 
                                        :alt="($i18n.locale == 'ar') ? gallery.ar.image_alt : gallery.en.image_alt" 
                                        :title="($i18n.locale == 'ar') ? gallery.ar.image_title : gallery.en.image_title" 
                                        class="w100 h450"/>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                </div>


                <div class="property_meta bg-black bottom40" v-if="item.icons">
                    <span v-for="(ico, idx) in item.icons" :key="idx">
                        <span v-if="ico">
                            <img 
                                :src="(ico.icon) ? ico.icon.image.url : ''" 
                                :alt="(ico.icon) ?($i18n.locale == 'ar') ? ico.icon.image.ar.image_alt : ico.icon.image.en.image_alt : ''"
                                :title="(ico.icon) ?($i18n.locale == 'ar') ? ico.icon.image.ar.image_title : ico.icon.image.en.image_title : ''"
                                style="width:20px; height:20px; margin-left:20px">
                            {{ ico.value }}
                            {{ ($i18n.locale == 'ar') ? ico.icon.ar.title : ico.icon.en.title }}
                        </span>
                    </span>
                </div>



                <div v-if="item.body_en">
                    <h2 
                        class="text-uppercase bottom20"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        <!-- {{ $t('app.property_description') }} -->
                    </h2>
                    <span :class="(this.$i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        v-html="($i18n.locale == 'ar') ? item.body_ar : item.body_en"
                        >
                    </span>
                </div>


                <div v-if="item.summary1_en">
                    <p><br/></p>
                    <h2 class="text-uppercase bottom20"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ $t('app.quick_summary') }}
                    </h2>
                    <div class="row property-d-table bottom40">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div v-html="($i18n.locale == 'ar') ? item.summary1_ar : item.summary1_en "></div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div v-html="($i18n.locale == 'ar') ? item.summary2_ar : item.summary2_en"></div>
                        </div>
                    </div>
                </div>


                <VideoCard
                    v-if="item.embed_video"
                    :embed_video="item.embed_video">
                </VideoCard>

                 <div v-if="item.features">
                    <h2 
                        class="text-uppercase bottom30"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ $t('app.property_map') }}
                    </h2>

                    <div class="row bottom40">
                        <div v-for="(media, idx) in item.features"
                            :key="idx"
                            class="col-md-4 col-sm-4 col-xs-12">

                            <div class="pro-img">
                            <figure class="wpf-demo-gallery">
                                <img 
                                    v-lazy="(media.image) ? media.image : ''" 
                                    :src="(media.image) ? media.image : ''" 
                                    :alt="($i18n.locale == 'ar') ? media.ar.image_alt : media.en.image_alt"
                                    :title="($i18n.locale == 'ar') ? media.ar.image_title : media.en.image_title"
                                    class="w100 h280"/>
                                <figcaption class="view-caption">    
                                <a @click="openGallery(idx)"
                                    data-imagelightbox="popup-btn" 
                                    class="fancybox">
                                    <i class="icon-focus"></i>
                                </a>
                                </figcaption>
                            </figure>
                            </div>
                        </div>
                    </div>
             
                    <LightBox
                        ref="lightbox"
                        :media="item.features"
                        :show-caption="true"
                        :show-light-box="false">
                    </LightBox>
                </div>
              </div>
                <div class="col-md-4" style="position: sticky;top: 3px;">
                    <ContactForm2
                        :property_id="item.id">
                    </ContactForm2>
                </div>
            </div>
          </div>
        </section>
        <!--  Property Detail End -->
        
    </div>
</template>


<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'Show',
    components: {
        Swiper,
        SwiperSlide,
        PageBanner: () => import('@/components/PageBanner.vue'),
        LightBox: () => import('@/views/lightbox/App.vue'),
        VideoCard: () => import('@/components/VideoCard.vue'),
        ContactForm2: () => import('@/components/ContactForm2.vue'),
    },
    directives: {
        swiper: directive,
    },
    data(){
        return {
            pgLoading: false,
            landing:true,
            item: '',
      
            icons: [
                  {
                    icon: '',
                    values: '4800 sq ft',
                  },
                  {
                    icon: '',
                    values: '2 Office Rooms',
                  },
                  {
                    icon: '',
                    values: '1 Bathroom',
                  },
                  {
                    icon: '',
                    values: 'TV Lounge',
                  },
                  {
                    icon: '',
                    values: '1 Garage',
                  },
            ],

            swiperOptions: {
                name: 'mySwiper',
                loop: true,
                speed: 700,
                autoplay: true,
                spaceBetween: 30,
                slidesPerView: 1,
                rtl:true,
                ltr:true,
                navigation: {
                    nextEl: '#swiperBtnNext',
                    prevEl: '#swiperBtnPrev'
                },
                breakpoints: {
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                }
              },
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            },


            features: [
                  {
                    image: '/assets/images/tmp/property-d-1-f-1.webp',
                    thumb: '/assets/images/tmp/property-d-1-f-1.webp',
                  },
                  {
                    image: '/assets/images/tmp/property-d-1-f-2.webp',
                    thumb: '/assets/images/tmp/property-d-1-f-2.webp',
                  },
                  {
                    image: '/assets/images/tmp/property-d-1-f-3.webp',
                    thumb: '/assets/images/tmp/property-d-1-f-3.webp',
                  },
                ],


            

        }
    },
    metaInfo() {
        return {
            title: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_title : this.item.meta.en.meta_title : 'meta_title',
            meta: [
                { 
                    name: 'description', 
                    content: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_description : this.item.meta.en.meta_description : 'meta_description'
                },
                { 
                    name: 'keywords', 
                    content: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_keywords : this.item.meta.en.meta_keywords : 'meta_keywords'
                },
                { 
                    name: 'title', 
                    content: (this.item.meta) ?(this.$i18n.locale == 'ar') ? this.item.meta.ar.meta_title : this.item.meta.en.meta_title : 'meta_title'
                }  
            ]
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        },
    },
    mounted() {},
    created() {

        this.fetchRow();
        this.fetchOthers();
    },
    methods: {
          

        fetchRow() {
            this.pgLoading = true;
            this.landing = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/properties/'+this.$route.params.slug,
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.item = res.data.item;
                var swipers = document.getElementsByClassName('swiper-container');
                for (var i =0; i< swipers.length; i++) {
                    if (localStorage.getItem('locale') == 'en') {
                        swipers[i].style.direction = "ltr";
                    } else {
                        swipers[i].style.direction = "rtl";
                    }
                }
            })
            .catch(() => {})
            .finally(() => {});
        },

        fetchOthers() {
            //this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/properties',
                method: 'GET',
                data: {},
                params: {
                    paginate: 3,
                }
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.others = res.data.item;
            })
            .catch(() => {})
            .finally(() => {});
        },

        
        sendMessage() {

        },

        prev() {
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        
        next() {
            this.$refs.mySwiper.$swiper.slideNext();
        },


        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        },

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
